body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  float: right;
  font-size: 18px;
}
.header2-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
@media screen and (min-width: 1080px) {
  .header2 {
    height: 64px;
  }
}
@media screen and (max-width: 767px) {
  .header2 {
    background-color: unset !important;
  }
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    font-size: 14px;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    color: #fff;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner3 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  font-size: 68px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 600;
  margin: 26px auto 38px;
  overflow: hidden;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  display: block;
  margin: 72px auto 0;
  background: #034365;
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
    background-image: url("https://s21.ax1x.com/2024/11/06/pAsqKcd.jpg") !important;
    background-size: cover !important;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  color: rgba(0, 0, 0, 0.85);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 1400px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
  .content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
    height: 120px;
  }
}
.content6-wrapper ul {
  list-style: none;
}
.content6-wrapper .content6 {
  height: 100%;
  display: flex;
  align-items: center;
}
.content6-wrapper .content6-text {
  min-height: 424px;
}
.content6-wrapper .content6-text > *.queue-anim-leaving {
  position: relative !important;
}
.content6-wrapper .content6-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 2em;
}
.content6-wrapper .content6-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
}
.content6-wrapper .content6-text ul {
  position: relative;
  display: inline-block;
}
.content6-wrapper .content6-text ul li {
  margin-bottom: 24px;
}
.content6-wrapper .content6-text ul li .content6-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.content6-wrapper .content6-text ul li .content6-title,
.content6-wrapper .content6-text ul li .content6-content {
  margin-left: 45px;
}
.content6-wrapper .content6-text ul li .content6-title {
  font-size: 14px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    height: 1000px;
    overflow: hidden;
  }
  .content6-wrapper ul {
    list-style: none;
  }
  .content6-wrapper .content6 {
    display: block;
  }
  .content6-wrapper .content6-img,
  .content6-wrapper .content6-text {
    display: block;
    width: 100%;
  }
  .content6-wrapper .content6-text > h1,
  .content6-wrapper .content6-text > p {
    text-align: center;
  }
  .content6-wrapper .content6-text > h1 {
    margin: 56px auto 16px;
  }
  .content6-wrapper .content6-text p {
    margin-bottom: 32px;
  }
  .content6-wrapper .content6-img {
    margin-top: 20px;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
div > .templates-wrapper > .jrhtw9ph4a-editor_css {
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.home-page > .header2-menu > .active {
  color: #ffffff;
}
.home-page > .header2-menu > .menu-item:hover {
  color: #e0e7ff;
  border-bottom-color: #ffffff;
}
#Banner3_0.banner3 {
  background-attachment: scroll, scroll;
  background-blend-mode: normal, normal;
  background-position: 0% 0%, 0% 0%;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-clip: padding-box, padding-box;
}
#Feature0_0 div > .ant-row > .m2km9yaz4k8-editor_css {
  position: static;
}
#Feature0_0 .ant-row > .ant-col > .m2kmdsbxebr-editor_css {
  min-height: 200px;
}
#Feature0_0 .ant-col > .content0-block-item > .m2kmjfe4ng-editor_css {
  text-align: left;
}
#Feature4_0 .ant-row > .ant-col > .m2lz0hzkgr-editor_css {
  padding: 0 20px 0 0;
}
#Content5_0.m2lza0d2q7-editor_css {
  background-color: rgba(250, 250, 250, 0);
}
#Feature0_0 .ant-col > .content0-block-item > .m2km7i990co-editor_css {
  text-align: left;
}
#Banner3_0 .banner3-text-wrapper > .m2mwursp9lj-editor_css {
  color: #ff8b6a;
}
#Banner3_0 .banner3-text-wrapper > .m2mwv29d0he-editor_css {
  color: #ff8b6a;
}
#Banner3_0 .banner3-text-wrapper > .m2mwv4wfd1k-editor_css {
  color: #ff8b6a;
}
#Banner3_0 .m2kla15mqpk-editor_css {
  display: block;
  height: 220px;
}
#Content13_0 .title-wrapper > .m2nfv1ij3qs-editor_css {
  font-weight: bold;
}
#Content13_0 .title-wrapper > .m2ng7mp6uv-editor_css {
  font-weight: bold;
}
#Banner3_0.m2klnzl4avq-editor_css {
  display: flex;
  align-items: center;
  background-image: url(https://s21.ax1x.com/2024/11/06/pAsqMjA.jpg);
  background-attachment: scroll, scroll;
  background-blend-mode: darken, normal;
  background-position: 0% 0%, 0% 0%;
  background-repeat: no-repeat, repeat;
  background-size: cover, auto;
  background-clip: border-box, padding-box;
  border-bottom-color: #ff8b6a;
}
#Nav2_0 .home-page > .m2x0hetwz1-editor_css {
  opacity: 0;
}
#Nav2_0 .home-page > .header2-menu > .m2mwvcm2f7p-editor_css {
  font-weight: bold;
  color: #ff8b6a;
  border-bottom-color: #ff8b6a;
}
#Nav2_0 .home-page > .header2-menu > .m2mwvf7nkpg-editor_css {
  font-weight: bold;
  color: #ff8b6a;
}
#Nav2_0 .home-page > .header2-menu > .m2mwvhb96y-editor_css {
  font-weight: bold;
  color: #ff8b6a;
}
#Nav2_0 .home-page > .header2-menu > .m2mwvjbut49-editor_css {
  font-weight: bold;
  color: #ff8b6a;
}
#Nav2_0 .home-page > .header2-menu > .m2mwvl1ycsp-editor_css {
  font-weight: bold;
  color: #ff8b6a;
}
#Nav2_0 .home-page > .header2-menu > .m2mwvmvdsh-editor_css {
  font-weight: bold;
  color: #ff8b6a;
}
#Feature0_1 .ant-col > .content0-block-item > .m347eg9ujtt-editor_css {
  width: 250px;
  height: 500px;
}
#Feature0_1 .ant-col > .content0-block-item > .m347ifd8qzd-editor_css {
  width: 250px;
  height: 500px;
}
#Feature0_1 .ant-col > .content0-block-item > .m347kxl90zg-editor_css {
  width: 250px;
  height: 500px;
}
#Feature0_1 .ant-col > .content0-block-item > .m347mas12z9-editor_css {
  width: 250px;
  height: 500px;
}
#Feature0_1 .ant-col > .content0-block-item > .m347mz0qicc-editor_css {
  width: 250px;
  height: 500px;
}
#Feature0_1 .ant-col > .content0-block-item > .m347p2xzhp9-editor_css {
  width: 250px;
  height: 500px;
}
@media screen and (max-width: 767px) {
  #Feature0_1 .ant-col > .content0-block-item > .m347eg9ujtt-editor_css {
    width: 160px;
    height: 320px;
  }
  #Feature0_1 .ant-col > .content0-block-item > .m347ifd8qzd-editor_css {
    width: 160px;
    height: 320px;
  }
  #Feature0_1 .ant-col > .content0-block-item > .m347kxl90zg-editor_css {
    width: 160px;
    height: 320px;
  }
  #Feature0_1 .ant-col > .content0-block-item > .m347mas12z9-editor_css {
    width: 160px;
    height: 320px;
  }
  #Feature0_1 .ant-col > .content0-block-item > .m347mz0qicc-editor_css {
    width: 160px;
    height: 320px;
  }
  #Feature0_1 .ant-col > .content0-block-item > .m347p2xzhp9-editor_css {
    width: 160px;
    height: 320px;
  }
}
#Feature0_1 .home-page > .title-wrapper > .m347shx0kuj-editor_css {
  text-align: left;
}
#Content13_0 .title-wrapper > .m2ng2wwp24l-editor_css {
  font-weight: bold;
  color: #ffffff;
  margin: 0px 0px;
  padding: 0px 0px;
}
#Content13_0 .title-wrapper > .m2ng4s3bk2-editor_css {
  color: #ffffff;
  margin: 0px 0px 16px;
}
#Content13_0 .m348fe699k-editor_css {
  color: #ffffff;
}
#Content13_0.m2lvi71k9hq-editor_css {
  background-clip: ;
  height: 300px;
  min-height: 0px;
  background-color: #ff8b6a;
  background-image: url('https://s21.ax1x.com/2024/11/05/pAsU8nf.png');
  background-position: 0% 0%;
  background-size: auto;
  padding: 56px 0px 0px;
}
#Feature0_0 .ant-col > .content0-block-item > .m2mw4nxayam-editor_css {
  width: 100%;
  height: 100%;
}
#Feature0_0 .ant-col > .content0-block-item > .m2mw2itxfa8-editor_css {
  width: 100%;
  height: 100%;
}
#Content5_0 .ant-col > .content5-block-content > .m358l0ve9q-editor_css {
  background-color: #ffffff;
  padding: 0% 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358nh7kz6-editor_css {
  background-color: #ffffff;
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358nomk9dm-editor_css {
  background-color: #ffffff;
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358nw65c8r-editor_css {
  background-color: #ffffff;
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358o27fi4-editor_css {
  background-color: #ffffff;
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358o9x110e-editor_css {
  background-color: #ffffff;
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358occm7wg-editor_css {
  background-color: #ffffff;
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358oh76skc-editor_css {
  background-color: #ffffff;
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358qg0geie-editor_css {
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358qoljbyb-editor_css {
  padding: 0%;
}
#Content5_0 .ant-col > .content5-block-content > .m358rneocv-editor_css {
  padding: 0%;
}
#Content5_0 .home-page > div > .m2mx1pi9uwt-editor_css {
  justify-content: space-around;
}
#Feature0_0 .ant-col > .content0-block-item > .m358xrw6mne-editor_css {
  margin: 20px auto;
}
#Feature0_0 .ant-col > .content0-block-item > .m358y2ed83q-editor_css {
  margin: 20px auto;
}
#Feature0_2 .home-page > div > .m3593j4g47k-editor_css {
  justify-content: center;
}
#Feature0_2 .ant-col > .content0-block-item > .m3594z47k2-editor_css {
  text-align: left;
}
#Feature0_2 .ant-col > .content0-block-item > .m3595prh7ab-editor_css {
  text-align: left;
  margin: 12px 0 0;
}
#Feature0_2 .ant-col > .content0-block-item > .m3596jf508k-editor_css {
  width: auto;
  height: 100%;
}
#Content5_0 .m3599rv32ee-editor_css {
  padding: 64px 24px;
}
#Feature4_0 .m359b2vd6hi-editor_css {
  padding: 64px 24px;
}
#Feature0_1 .home-page > div > .m359cjlj83q-editor_css {
  padding: 0;
}
#Feature0_0 .home-page > div > .m2km6cz1mq8-editor_css {
  justify-content: center;
  text-align: center;
  padding: 0;
}

